import * as React from "react"
import "../index.css"

import { Box } from "@chakra-ui/react"
import Footer from "../Home/Footer"
import HomeNavBar from "../Home/HomeNavBar"
import BlogsScroll from "../Home/BlogsScroll"
import WantMoreInWela from "./WantMoreInWela"

const EventsPage = () => {
  return (
    <>
      <HomeNavBar />
      <Box
        w="100%"
        p={4}
        bgColor={"#3952D3"}
        color="white"
        position={"relative"}
        py={{ "2xl": "40", xl: "36", lg: "32", base: "24" }}
      >
        <Box zIndex={5} position={"relative"}>
          <Box
            fontSize={{ "2xl": 70, xl: 60, lg: 40, base: 25 }}
            fontWeight={700}
            textAlign={"center"}
          >
            EVENTS
          </Box>
        </Box>
      </Box>

      <Box
        px={{ "2xl": "20rem", xl: "8rem", lg: "5rem", base: "1rem" }}
        pt={"20"}
        bgColor="#FBFBFB"
      >
        <Box fontSize={16} fontWeight={600} mb="6">
          JANUARY 18, 2022
        </Box>
        <BlogsScroll />
      </Box>
      <Box
        px={{ "2xl": "20rem", xl: "8rem", lg: "5rem", base: "1rem" }}
        py={"20"}
        bgColor="#FBFBFB"
      >
        <Box fontSize={16} fontWeight={600} mb="6">
          FEBRUARY 18, 2022
        </Box>
        <BlogsScroll />
      </Box>

      <WantMoreInWela />
      <Footer />
    </>
  )
}

export default EventsPage
